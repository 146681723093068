import React, {useState, useRef } from 'react';
import {StaticMap} from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import {FlyToInterpolator} from 'deck.gl';
import {GeoJsonLayer} from '@deck.gl/layers';
import GL from '@luma.gl/constants';
import {LightingEffect, AmbientLight, _SunLight as SunLight} from '@deck.gl/core';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {ResponsiveContainer, RadialBarChart, RadialBar, Legend, LabelList, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid} from 'recharts';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Stations from './Stations.json';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import * as d3 from 'd3-request';
import StationDataNew from './StationDataNew.json';
import {findIndex} from 'lodash';
import {FillStyleExtension} from '@deck.gl/extensions';


// Set your mapbox token here
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGhhbHBlcm4iLCJhIjoiY2p3MHFvZHg2MDcyczQ4bXBjNW85aDh2OCJ9.OUluk6vAGe5BVXLOiGIoQQ'; // eslint-disable-line

// Source data GeoJSON
const DATA_URL2 =
  'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/geojson/vancouver-blocks.json'; // eslint-disable-line

const DATA_URL = {
    bikeEuclidean: `${process.env.PUBLIC_URL}/data/bikeEuclidean.geojson`, // eslint-disable-line
    bikeNetwork: `${process.env.PUBLIC_URL}/data/bikeNetwork.geojson`, // eslint-disable-line
    bikePerceived: `${process.env.PUBLIC_URL}/data/bikePerceived.geojson`, // eslint-disable-line
    bikeSc1: `${process.env.PUBLIC_URL}/data/bikeSc1.geojson`, // eslint-disable-line
    bikeSc2: `${process.env.PUBLIC_URL}/data/bikeSc2.geojson`, // eslint-disable-line
    bikeSc12: `${process.env.PUBLIC_URL}/data/bikeSc12.geojson`, // eslint-disable-line
    walkEuclidean: `${process.env.PUBLIC_URL}/data/walkEuclidean.geojson`, // eslint-disable-line
    walkNetwork: `${process.env.PUBLIC_URL}/data/walkNetwork.geojson`, // eslint-disable-line
    walkPerceived: `${process.env.PUBLIC_URL}/data/walkPerceived.geojson`, // eslint-disable-line
    stations: `${process.env.PUBLIC_URL}/data/stations.json`, // eslint-disable-line
    walkSc3: `${process.env.PUBLIC_URL}/data/walkSc3.geojson`, // eslint-disable-line
    walkSc4: `${process.env.PUBLIC_URL}/data/walkSc4.geojson`, // eslint-disable-line
    walkSc34: `${process.env.PUBLIC_URL}/data/walkSc34.geojson`, // eslint-disable-line
};

const WEBGL_PARAMETERS = {
  [GL.DEPTH_TEST]: false,
  // [GL.BLEND]: true,
  // [GL.BLEND_SRC_RGB]: GL.ONE,
  // [GL.BLEND_DST_RGB]: GL.ONE,
  // [GL.BLEND_EQUATION_ALPHA]: GL.FUNC_ADD,
};

const MapContainer = styled.div`
  width:100%;
  height:100%;
  position:fixed;
  left:0;
  top:0;
`

const BaseMapSelect = styled(Paper)`
  position:fixed;
  bottom:40px;
  right:0.5vw;
  z-index:10;
  background:white;
  padding:20px;
  @media (max-width:700px) {
    bottom:120px;
  }
`

const LayersSelect = styled.div`
  position:fixed;
  top:0px;
  right:0.5vw;
  z-index:10;
  max-width:30vw;
  box-sizing:border-box;
  .MuiPaper-root.MuiPaper-elevation5.MuiPaper-rounded {
    padding:10px 20px;
    margin:10px 0;
  }
  p {
    text-align:left;
    font-weight:bold;
  }
  @media (max-width:1440px) {
    max-width:50vw;
  }
  @media (max-width:1024px) {
    width:100vw;
    right:0;
    top:40px;
    padding:0 20px;
    max-width:100vw;
    margin:0;
  }

`

const SideBarDrawer = styled(SwipeableDrawer)`
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 { 
    padding:20px;
    width:30vw;
    padding-bottom:50vh;
    box-sizing:border-box;
    h1 {
      font-size:300%;
      font-weight:bold;
    }
    h2 { 
      font-size:200%;
    }
    @media (max-width:1600px) {
      width:50vw;
    }
    @media (max-width:1024px) {
      width:70vw;
    }
    
    @media (max-width:700px) {
      width:100%;
    }
  }
`

const DrawerButton = styled(Button)`
  float:left;
  span.MuiButton-label {
    color:black;
    padding:0;
    font-size:12px;
  }
  span.MuiButton-label div.MuiPaper-root {
    padding:2px 6px;
  }
`

const LogoContainer = styled.div`
  position:fixed;
  max-width:450px;
  bottom:40px;
  left:0;
  opacity:0.85;
  img {
    box-sizing: border-box;
    padding:10px;
    width:33%;
    display:inline-block;
    transition:250ms all;
  }
  img:hover {
    transform:translateY(-10px);
  }
`

const INITIAL_VIEW_STATE = {
  latitude: 42.354,
  longitude: -71.079,
  zoom: 11.28,
  maxZoom: 16,
  pitch: 0,
  bearing: 0
};

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0
});

const dirLight = new SunLight({
  timestamp: Date.UTC(2019, 7, 1, 22),
  color: [255, 255, 255],
  intensity: 1.0,
  _shadow: true
});

const DataMeasures = [
  "Employment",
  "Population",
  "Amenities",
  "GFA",
  "Vacant Land",
  "Area",
  "Length"
]

const DataUnits = {
    "Employment": "Number of Jobs",
    "Population": "Number of People",
    "Amenities": "Number of Amenities",
    "GFA": "M Ft²",
    "Vacant Land": "M Ft²",
    "Area": "Square Miles",
    "Length": "Linear Miles"
}

const WalkData = [
  {
    "name": "Perceived Distance",
    "Employment":272337,
    "Population":146139,
    "Amenities":5621,
    "Vacant Land":87.8,
    "Area":22.8,
    "Length":527,
    "GFA": 2482,
    "Employment_PCT":`${(272337/715246*100).toFixed(2)}%`,
    "Population_PCT":`${(146139/616338*100).toFixed(2)}%`,
    "Amenities_PCT":`${(5621/13195*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(87.8/575.2*100).toFixed(2)}%`,
    "Area_PCT":`${(22.8/105.8*100).toFixed(2)}%`,
    "Length_PCT":`${(527/1878*100).toFixed(2)}%`,
    "GFA_PCT": `${(2482/8226*100).toFixed(2)}%`,
    "fill": "#d36326"
  },{

    "name": "Network Distance",
    "Employment":517846,
    "Population":361464,
    "Amenities":10237,
    "Vacant Land":182.8,
    "Area":50.6,
    "Length":1163,
    "GFA": 5271,
    "Employment_PCT":`${(517846/715246*100).toFixed(2)}%`,
    "Population_PCT":`${(361464/616338*100).toFixed(2)}%`,
    "Amenities_PCT":`${(10237/13195*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(182.8/575.2*100).toFixed(2)}%`,
    "Area_PCT":`${(50.6/105.8*100).toFixed(2)}%`,
    "Length_PCT":`${(1163/1878*100).toFixed(2)}%`,
    "GFA_PCT": `${(5271/8226*100).toFixed(2)}%`,
    "fill": "#a81e1f"
  },{
    "name": "Circle Distance",
    "Employment":715246,
    "Population":616338,
    "Amenities":13195,
    "Vacant Land":575.2,
    "Area":105.8,
    "Length":1878,
    "GFA": 8226,
    "Employment_PCT":``,
    "Population_PCT":``,
    "Amenities_PCT":``,
    "Vacant Land_PCT":``,
    "Area_PCT":``,
    "Length_PCT":``,
    "GFA_PCT":``,
    "fill": "#593131"
  }]

const BikeData = [
  {
    "name": "Perceived Distance",
    "Employment":605809,
    "Population":586624,
    "Amenities":13155,
    "Vacant Land":5013.6,
    "Area":102.3,
    "Length":2179,
    "GFA": 8000,
    "Employment_PCT":`${(605809/1844854*100).toFixed(2)}%`,
    "Population_PCT":`${(586624/2531814*100).toFixed(2)}%`,
    "Amenities_PCT":`${(13155/34966*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(5013.6/5013.6*100).toFixed(2)}%`,
    "Area_PCT":`${(102.3/712.3*100).toFixed(2)}%`,
    "Length_PCT":`${(2179/9104*100).toFixed(2)}%`,
    "GFA_PCT": `${(8000/29930*100).toFixed(2)}%`,
    "fill": "#63c843"
  },{
    "name": "Network Distance",
    "Employment":1546666,
    "Population":1794155,
    "Amenities":29167,
    "Vacant Land":1075.4,
    "Area":317.9,
    "Length":6436,
    "GFA": 22208,
    "Employment_PCT":`${(1546666/1844854*100).toFixed(2)}%`,
    "Population_PCT":`${(1794155/2531814*100).toFixed(2)}%`,
    "Amenities_PCT":`${(29167/34966*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(1075.4/5013.6*100).toFixed(2)}%`,
    "Area_PCT":`${(317.9/712.3*100).toFixed(2)}%`,
    "Length_PCT":`${(6436/9104*100).toFixed(2)}%`,
    "GFA_PCT": `${(22208/29930*100).toFixed(2)}%`,
    "fill": "#21697a"
  },{
    "name": "Circle Distance",
    "Employment":1844854,
    "Population":2531814,
    "Amenities":34966,
    "Vacant Land":5013.6,
    "Area":712.3,
    "Length":9104,
    "GFA": 29930,
    "Employment_PCT":``,
    "Population_PCT":``,
    "Amenities_PCT":``,
    "Vacant Land_PCT":``,
    "Area_PCT":``,
    "Length_PCT":``,
    "GFA_PCT":``,
    "fill": "#2b4178"
  }]

const BikeScData = [
  {
    "name":"Baseline",
    "Employment":605809,
    "Population":586624,
    "Amenities":13155,
    "Vacant Land":332.5,
    "Area":102.3,
    "Length":2179,
    "GFA": 8000,
    "Employment_PCT":`Baseline`,
    "Population_PCT":`Baseline`,
    "Amenities_PCT":`Baseline`,
    "Vacant Land_PCT":`Baseline`,
    "Area_PCT":`Baseline`,
    "Length_PCT":`Baseline`,
    "GFA_PCT":`Baseline`,
    "fill":"#99c78b"
  },{
    "name":"Scenario 3",
    "Employment":770971,
    "Population":780136,
    "Amenities":15768,
    "Vacant Land":455.0,
    "Area":143.7,
    "Length":2965.1,
    "GFA":10301,
    "Employment_PCT":`${(770971/605809*100).toFixed(2)}%`,
    "Population_PCT":`${(780136/586624*100).toFixed(2)}%`,
    "Amenities_PCT":`${(15768/13155*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(455/332.5*100).toFixed(2)}%`,
    "Area_PCT":`${(143.7/102.3*100).toFixed(2)}%`,
    "Length_PCT":`${(2965.1/2179*100).toFixed(2)}%`,
    "GFA_PCT": `${(10301/8000*100).toFixed(2)}%`,
    "fill":"#002278"
  },{
    "name":"Scenario 4",
    "Employment":905359,
    "Population":835875,
    "Amenities":17818,
    "Vacant Land":396.4,
    "Area":122.3,
    "Length":2699.2,
    "GFA":11041,
    "Employment_PCT":`${(905359/605809*100).toFixed(2)}%`,
    "Population_PCT":`${(835875/586624*100).toFixed(2)}%`,
    "Amenities_PCT":`${(17818/13155*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(396.4/332.5*100).toFixed(2)}%`,
    "Area_PCT":`${(122.3/102.3*100).toFixed(2)}%`,
    "Length_PCT":`${(2699.2/2179*100).toFixed(2)}%`,
    "GFA_PCT": `${(11041/8000*100).toFixed(2)}%`,
    "fill":"#00667a"
  },{
    "name":"Scenarios 3+4",
    "Employment":1086611,
    "Population":1062747,
    "Amenities":20838,
    "Vacant Land":543.8,
    "Area":169.5,
    "Length":3604.0,
    "GFA":13641,
    "Employment_PCT":`${(1086611/605809*100).toFixed(2)}%`,
    "Population_PCT":`${(1062747/586624*100).toFixed(2)}%`,
    "Amenities_PCT":`${(20838/13155*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(543.8/332.5*100).toFixed(2)}%`,
    "Area_PCT":`${(169.5/102.3*100).toFixed(2)}%`,
    "Length_PCT":`${(3604/2179*100).toFixed(2)}%`,
    "GFA_PCT": `${(13641/8000*100).toFixed(2)}%`,
    "fill":"#2ec700"
  }
]
const WalkScData = [
  {
    "name":"Baseline",
    "Employment":272337,
    "Population":146139,
    "Amenities":5621,
    "Vacant Land":87.8,
    "Area":22.8,
    "Length":527,
    "GFA": 2482,
    "Employment_PCT":`Baseline`,
    "Population_PCT":`Baseline`,
    "Amenities_PCT":`Baseline`,
    "Vacant Land_PCT":`Baseline`,
    "Area_PCT":`Baseline`,
    "Length_PCT":`Baseline`,
    "GFA_PCT":`Baseline`,
    "fill":"#d36326"
  },
  {
    "name":"Scenario 1",
    "Employment":310047,
    "Population":178279,
    "Amenities":6379,
    "Vacant Land":101.9,
    "Area":27,
    "Length":623.6,
    "GFA":2918,
    "Employment_PCT":`${(310047/272337*100).toFixed(2)}%`,
    "Population_PCT":`${(178279/146139*100).toFixed(2)}%`,
    "Amenities_PCT":`${(6379/5621*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(101.9/87.8*100).toFixed(2)}%`,
    "Area_PCT":`${(27/22.8*100).toFixed(2)}%`,
    "Length_PCT":`${(623.6/527*100).toFixed(2)}%`,
    "GFA_PCT": `${(2918/2482*100).toFixed(2)}%`,
    "fill":"#590000"
  },
  {
    "name":"Scenario 2",
    "Employment":278762,
    "Population":151524,
    "Amenities":5806,
    "Vacant Land":88.5,
    "Area":23.3,
    "Length":538.3,
    "GFA":2541,
    "Employment_PCT":`${(278762/272337*100).toFixed(2)}%`,
    "Population_PCT":`${(151524/146139*100).toFixed(2)}%`,
    "Amenities_PCT":`${(5806/5621*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(88.5/87.8*100).toFixed(2)}%`,
    "Area_PCT":`${(23.3/22.8*100).toFixed(2)}%`,
    "Length_PCT":`${(538.3/527*100).toFixed(2)}%`,
    "GFA_PCT": `${(2541/2482*100).toFixed(2)}%`,
    "fill":"#a80000"
  },
  {
    "name":"Scenarios 1+2",
    "Employment":316976,
    "Population":182939,
    "Amenities":6562,
    "Vacant Land":103.5,
    "Area":27.5,
    "Length":635.7,
    "GFA":2983,
    "Employment_PCT":`${(316976/272337*100).toFixed(2)}%`,
    "Population_PCT":`${(182939/146139*100).toFixed(2)}%`,
    "Amenities_PCT":`${(6562/5621*100).toFixed(2)}%`,
    "Vacant Land_PCT":`${(103.5/87.8*100).toFixed(2)}%`,
    "Area_PCT":`${(27.5/22.8*100).toFixed(2)}%`,
    "Length_PCT":`${(635.7/527*100).toFixed(2)}%`,
    "GFA_PCT": `${(2983/2482*100).toFixed(2)}%`,
    "fill":"#ff5900"
  },
]

const BikeScenarios = [
  "Scenario 3 (bike): The first scenario imagines all streets within ½-mile of a station to be a bike lane and the rest of the streets within the 1 ½-mile radius as being a bike route (i.e. sharrows). All bike paths were left untouched, as those are the most protected and segregated bike facilities.",
  "Scenario 4 (bike): The second scenario removes one-way street deterrents for cyclists. This can either be done by switching one-way streets to bi-directional or adding two-way bike lanes in the one-way street.",
  "Scenario 3 + 4 (bike): Combines both the first and second scenario to illustrate the effects from implementing both policies."
]

const WalkScenarios = [
  "Scenario 1 (walk): The third scenario reduces the speed limit to 20 mph for streets under 45mph and reduces the speed limit by 10 mph for streets at 45 mph or higher.",
  "Scenario 2 (walk): The fourth scenario focuses on increasing amenities within ½-mile of a commuter rail station by 50%.",
  "Scenario 1 + 2 (walk): Combines both the third and fourth scenario to illustrate the effects from implementing both policies."
]


function getTooltip({object}) {
  return (
    object && { 
      html: `\
  <div><b>${object.properties.STATION.toLowerCase()}</b></div>
  <div><b>${object.properties.POLYNAME}</b></div>
  <div>Pop: ${object.properties.Population.toLocaleString()}</div>
  <div>Employment: ${object.properties.Employment.toLocaleString()}</div>
  <div>Amenities: ${object.properties.Amenities.toLocaleString()}</div>
  <div>Built GFA: ${object.properties.GFA.toLocaleString()} M ft²</div>
  <div>Vacant Land Area: ${object.properties.VacantLand.toLocaleString()} M ft²</div>
  <div>Total Area: ${object.properties.CoverArea.toLocaleString()} M ft²</div>
  `
    }
  );
}

const BikeEucCheck = withStyles({
  root: {
    color: '#545e78',
    '&$checked': {
      color: '#2b4178',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BikeNetworkCheck = withStyles({
  root: {
    color: '#216a7a',
    '&$checked': {
      color: '#56747a',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const BikePerceivedCheck = withStyles({
  root: {
    color: '#63c843',
    '&$checked': {
      color: '#99c78b',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ScenarioRadio = withStyles({
  root: {
    color: '#222222',
    '&$checked': {
      color: '#222222',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

// const BikeSc2Radio= withStyles({
//   root: {
//     color: '#00667a',
//     '&$checked': {
//       color: '#00667a',
//     },
//   },
//   checked: {},
// })((props) => <Radio color="default" {...props} />);

// const BikeSc12Radio = withStyles({
//   root: {
//     color: '#2ec700',
//     '&$checked': {
//       color: '#2ec700',
//     },
//   },
//   checked: {},
// })((props) => <Radio color="default" {...props} />);

const WalkEucCheck = withStyles({
  root: {
    color: '#593e3e',
    '&$checked': {
      color: '#593131',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const WalkNetworkCheck = withStyles({
  root: {
    color: '#a87676',
    '&$checked': {
      color: '#a81e1f',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const WalkPerceivedCheck = withStyles({
  root: {
    color: '#d4aa94',
    '&$checked': {
      color: '#d36326',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

// const WalkSc3Radio = withStyles({
//   root: {
//     color: '#590000',
//     '&$checked': {
//       color: '#590000',
//     },
//   },
//   checked: {},
// })((props) => <Radio color="default" {...props} />);

// const WalkSc4Radio = withStyles({
//   root: {
//     color: '#a80000',
//     '&$checked': {
//       color: '#a80000',
//     },
//   },
//   checked: {},
// })((props) => <Radio color="default" {...props} />);

// const WalkSc34Radio = withStyles({
//   root: {
//     color: '#ff5900',
//     '&$checked': {
//       color: '#ff5900',
//     },
//   },
//   checked: {},
// })((props) => <Radio color="default" {...props} />);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{paddingBottom:"10px"}}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function MainMap({data = DATA_URL2, mapStyle = 'mapbox://styles/dhalpern/ck9at4p4w1h081illlzoircd0?fresh=true'}) {
  const [effects] = useState(() => {
    const lightingEffect = new LightingEffect({ambientLight, dirLight});
    lightingEffect.shadowColor = [0, 0, 0, 0.5];
    return [lightingEffect];
  });
  
  const [glContext, setGLContext] = useState();
  const deckRef = useRef(null);
  const [currMap, setCurrMap] = useState(`mapbox://styles/dhalpern/ckcy62n4k1j6p1jpb2dqb7kdk?fresh=true`);
  const mapRef = useRef(null);
  const [currLayers, setCurrLayers] = useState({
    bikeEuclidean: false,
    bikeNetwork: false,
    bikePerceived: false,
    bikeSc1:false,
    bikeSc2:false,
    bikeSc12:false,
    walkEuclidean: false,
    walkNetwork: true,
    walkPerceived: true,
    walkSc3:false,
    walkSc4:false,
    walkSc34:false,
  });
  const [currBikeScenario, setCurrBikeScenario] = useState("");
  const [currBikeRadio, setCurrBikeRadio] = React.useState(null);
  const [currWalkScenario, setCurrWalkScenario] = useState("");
  const [currWalkRadio, setCurrWalkRadio] = React.useState(null);
  const [drawer, setDrawer] = useState(true);
  // const [currStation, setStationValue] = useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const [legend1Open, setLegend1Open] = useState(true);
  const [legend2Open, setLegend2Open] = useState(true);
  const [currMeasure, setCurrMeasure] = useState("Employment");
  // const [stationData, setStationData] = useState(null);
  const [currStationData, setCurrStationData] = useState(null);
  
  const handleBaseMap = (event) => {
    setCurrMap(event.target.value);
  };

  const handleLayers = (event) => {
    setCurrLayers({ ...currLayers, [event.target.name]: event.target.checked });
  };

  const handleBikeScenario = (index) => {
    if (currBikeScenario === BikeScenarios[index]) {
      setCurrBikeScenario(null)
    } else {
    setCurrBikeScenario(BikeScenarios[index])
    }
  }

  const handleWalkScenario = (index) => {
    if (currWalkScenario === WalkScenarios[index]) {
      setCurrWalkScenario(null)
    } else {
      setCurrWalkScenario(WalkScenarios[index])
    }
  }

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMeasure = (event) => {
    setCurrMeasure(event.target.value)
  }

  const handleBikeRadio = (event) => {
    
    setCurrLayers({ 
      ...currLayers,     
      bikeSc1:false,
      bikeSc2:false,
      bikeSc12:false,
      [event.target.value]: true
    });

    setCurrBikeRadio(event.target.value);
  };

  const handleBikeReset = (event) => {
    if (event.target.value === currBikeRadio) {
      setCurrLayers({ 
        ...currLayers,     
        bikeSc1:false,
        bikeSc2:false,
        bikeSc12:false,
      });

      setCurrBikeRadio(null);
    }
  }
  
  const handleWalkRadio = (event) => {
    console.log(event.target)
    setCurrLayers({ 
      ...currLayers,     
      walkSc3:false,
      walkSc4:false,
      walkSc34:false,
      [event.target.value]: true
    });

    setCurrWalkRadio(event.target.value);
  };

  const handleWalkReset = (event) => {
    if (event.target.value === currWalkRadio) {
      setCurrLayers({ 
        ...currLayers,     
        walkSc3:false,
        walkSc4:false,
        walkSc34:false,
      });

      setCurrWalkRadio(null);
    }
  }

  const handleLegendOpen = () => {
    setLegend1Open(true)
    setLegend2Open(true)
  }

  const handleAutoComplete = (event, newValue) => {
    if (newValue!=null){
      // setStationValue(newValue.Name);
      setViewState(viewState => ({
        ...viewState,
        longitude: newValue.Location[0],
        latitude: newValue.Location[1],
        zoom:13.8,
        transitionDuration: 2500,
        transitionInterpolator: new FlyToInterpolator()
      }))
      setCurrStationData(StationDataNew[findIndex(StationDataNew, o => o.STATION === newValue.Name)])
      console.log(currStationData)
    } else {
      // setStationValue(newValue);
    }
  };

  
  const MeasureSelect = () => {
    return (
      <FormControl>
        <InputLabel id="demo-simple-select-label">Metric</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currMeasure}
          onChange={handleMeasure}
        >
          {DataMeasures.map(o => {
            return (
              <MenuItem value={o}>{o}</MenuItem>
            )
          })
          }
        </Select>
    </FormControl>
    )
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
  };
  const layers = (layerList) => {
    
    let allLayers = {
      bikeEuclidean: new GeoJsonLayer({
        id: 'bikeEuclidean',
        data: DATA_URL.bikeEuclidean,
        opacity: 1*currLayers.bikeEuclidean,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: true,
        getFillColor: f => [44,66,121],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.bikeEuclidean
      }),
      bikeNetwork: new GeoJsonLayer({
          id: 'bikeNetwork',
          data: DATA_URL.bikeNetwork,
          opacity: 1*currLayers.bikeNetwork,
          stroked: false,
          filled: true,
          extruded: false,
          wireframe: false,
          getFillColor: f => [33,106,123],
          getLineColor: f=> [0,0,0],
          getLineWidth: 2,
          lineWidthMinPixels:1,
          pickable: currLayers.bikeNetwork
      }),
      bikeSc12: new GeoJsonLayer({
        id: 'bikeSc12',
        data: DATA_URL.bikeSc12,
        opacity: 1*currLayers.bikeSc12,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: false,
        getFillColor: f => [0,0,0],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.bikeSc12,
        // props added by FillStyleExtension
        fillPatternAtlas: `${process.env.PUBLIC_URL}/spriteSheet.png`,
        fillPatternMapping: `${process.env.PUBLIC_URL}/spriteInfo.json`,
        getFillPattern: f => 'lines',
        getFillPatternScale: 1,
        getFillPatternOffset: [0, 0],
        // Define extensions
        extensions: [new FillStyleExtension({pattern: true})]
      }),
      bikeSc2: new GeoJsonLayer({
        id: 'bikeSc2',
        data: DATA_URL.bikeSc2,
        opacity: 1*currLayers.bikeSc2,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: false,
        getFillColor: f => [0,0,0],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.bikeSc2,
        // props added by FillStyleExtension
        fillPatternAtlas: `${process.env.PUBLIC_URL}/spriteSheet.png`,
        fillPatternMapping: `${process.env.PUBLIC_URL}/spriteInfo.json`,
        getFillPattern: f => 'lines',
        getFillPatternScale: 1,
        getFillPatternOffset: [0, 0],
        // Define extensions
        extensions: [new FillStyleExtension({pattern: true})]
      }),
      bikeSc1: new GeoJsonLayer({
        id: 'bikeSc1',
        data: DATA_URL.bikeSc1,
        opacity: 1*currLayers.bikeSc1,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: false,
        getFillColor: f => [0,0,0],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.bikeSc1,
        // props added by FillStyleExtension
        fillPatternAtlas: `${process.env.PUBLIC_URL}/spriteSheet.png`,
        fillPatternMapping: `${process.env.PUBLIC_URL}/spriteInfo.json`,
        getFillPattern: f => 'lines',
        getFillPatternScale: 1,
        getFillPatternOffset: [0, 0],
        // Define extensions
        extensions: [new FillStyleExtension({pattern: true})]
      }),
      bikePerceived: new GeoJsonLayer({
        id: 'bikePerceived',
        data: DATA_URL.bikePerceived,
        opacity: 1*currLayers.bikePerceived,
        stroked: false,
        filled: true,
        extruded: false,
        wireframe: false,
        getFillColor: f => [100,202,68],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.bikePerceived
      }),
      walkEuclidean: new GeoJsonLayer({
        id: 'walkEuclidean',
        data: DATA_URL.walkEuclidean,
        opacity: 1*currLayers.walkEuclidean,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: true,
        getFillColor: f => [90,50,50],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.walkEuclidean
      }),
      walkNetwork: new GeoJsonLayer({
          id: 'walkNetwork',
          data: DATA_URL.walkNetwork,
          opacity: 1*currLayers.walkNetwork,
          stroked: false,
          filled: true,
          extruded: false,
          wireframe: false,
          getFillColor: f => [170,30,31],
          getLineColor: f=> [0,0,0],
          getLineWidth: 2,
          lineWidthMinPixels:1,
          pickable: currLayers.walkNetwork
      }),
      walkSc34: new GeoJsonLayer({
        id: 'walkSc34',
        data: DATA_URL.walkSc34,
        opacity: 1*currLayers.walkSc34,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: false,
        getFillColor: f => [0,0,0],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.walkSc34,
        // props added by FillStyleExtension
        fillPatternAtlas: `${process.env.PUBLIC_URL}/spriteSheet.png`,
        fillPatternMapping: `${process.env.PUBLIC_URL}/spriteInfo.json`,
        getFillPattern: f => 'dots',
        getFillPatternScale: 1,
        getFillPatternOffset: [0, 0],
        // Define extensions
        extensions: [new FillStyleExtension({pattern: true})]
      }),
      walkSc3: new GeoJsonLayer({
        id: 'walkSc3',
        data: DATA_URL.walkSc3,
        opacity: 1*currLayers.walkSc3,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: false,
        getFillColor: f => [0,0,0],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.walkSc3,
        // props added by FillStyleExtension
        fillPatternAtlas: `${process.env.PUBLIC_URL}/spriteSheet.png`,
        fillPatternMapping: `${process.env.PUBLIC_URL}/spriteInfo.json`,
        getFillPattern: f => 'dots',
        getFillPatternScale: 1,
        getFillPatternOffset: [0, 0],
        // Define extensions
        extensions: [new FillStyleExtension({pattern: true})]
      }),
      walkSc4: new GeoJsonLayer({
        id: 'walkSc4',
        data: DATA_URL.walkSc4,
        opacity: 1*currLayers.walkSc4,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: false,
        getFillColor: f => [0,0,0],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.walkSc4,
        // props added by FillStyleExtension
        fillPatternAtlas: `${process.env.PUBLIC_URL}/spriteSheet.png`,
        fillPatternMapping: `${process.env.PUBLIC_URL}/spriteInfo.json`,
        getFillPattern: f => 'dots',
        getFillPatternScale: 1,
        getFillPatternOffset: [0, 0],
        // Define extensions
        extensions: [new FillStyleExtension({pattern: true})]
      }),
      walkPerceived:  new GeoJsonLayer({
        id: 'walkPerceived',
        data: DATA_URL.walkPerceived,
        opacity: 1*currLayers.walkPerceived,
        stroked: false,
        filled: true,
        extruded: false,
        wireframe: false,
        getFillColor: f => [214,100,38],
        getLineColor: f=> [0,0,0],
        getLineWidth: 2,
        lineWidthMinPixels:1,
        pickable: currLayers.walkPerceived
      }),
      // new TextLayer({
      //   id: 'stations-layer',
      //   data: DATA_URL.stations,
      //   pickable: false,
      //   getPosition: d => d[0],
      //   getText: d => d[1],
      //   getSize: 32,
      //   getAngle: 0,
      //   getElevation:10,
      //   getTextAnchor: 'middle',
      //   getAlignmentBaseline: 'center'
      // })
    }
    let layerDump = [];

    for (const layer in layerList) {
      if(layerList[layer]===true) {
        layerDump.push(allLayers[layer])
      }
    }
    return layerDump
    
  }

  const StationDataTable = (data) => {

    if (data!== null){
      
      let conversionTable = {
        "Employment":"JOBS",
        "Population":"POP",
        "Amenities":"AMEN",
        "GFA":"GFA",
        "Vacant Land":"VACANT",
        "Area":"AREA",
        "Length":"LENGTH"
      }

      let tempWalkData = [{
        name: "Circle",
        "fill": "#593131"
      },{
        name: "Network",
        "fill": "#a81e1f"
      },{
        name: "Perceived",
        "fill": "#d36326"
      },{
        name: "Scenario 1",
        "fill":"#590000"
      },{
        name: "Scenario 2",
        "fill":"#a80000"
      },{
        name: "Scenario 1 + 2",
        "fill":"#ff5900"
      }]

      let tempBikeData = [{
        name: "Circle",
        "fill": "#2b4178"
      },{
        name: "Network",
        "fill": "#21697a"
      },{
        name: "Perceived",
        "fill": "#63c843"
      },{
        name: "Scenario 3",
        "fill":"#002278"
      },{
        name: "Scenario 4",
        "fill":"#00667a"
      },{
        name: "Scenario 3 + 4",
        "fill":"#2ec700"
      },]

      DataMeasures.forEach(d => {
        tempWalkData[0][d] = data[`WALK_C_${conversionTable[d]}`]
        tempWalkData[1][d] = data[`WALK_N_${conversionTable[d]}`]
        tempWalkData[2][d] = data[`WALK_P_${conversionTable[d]}`]
        tempWalkData[3][d] = data[`WALK_SC1_${conversionTable[d]}`]
        tempWalkData[4][d] = data[`WALK_SC2_${conversionTable[d]}`]
        tempWalkData[5][d] = data[`WALK_SC12_${conversionTable[d]}`]
        tempBikeData[0][d] = data[`BIKE_C_${conversionTable[d]}`]
        tempBikeData[1][d] = data[`BIKE_N_${conversionTable[d]}`]
        tempBikeData[2][d] = data[`BIKE_P_${conversionTable[d]}`]
        tempBikeData[3][d] = data[`BIKE_SC3_${conversionTable[d]}`]
        tempBikeData[4][d] = data[`BIKE_SC4_${conversionTable[d]}`]
        tempBikeData[5][d] = data[`BIKE_SC34_${conversionTable[d]}`]
      })
      
      return (
        <div>
          <br /><br />
            Walksheds
            <table className="stationsDataTable">
              <tr>
                <th>Scenario</th>
                <th>Length (mi)</th>
                <th>Area (sq mi)</th>
                <th>Employment</th>
                <th>Amenities (N)</th>
                <th>Population</th>
                <th>Building Area (GFA M sq ft)</th>
                <th>Vacant Area (M sq ft)</th>
                <th>Bike Paths</th>
                <th>Bike Lanes</th>
                <th>Bike Routes</th>
                <th>Bike Facilities</th>
                </tr>
                <tr>
                  <td> Circle</td>
                  <td>{data.WALK_C_LENGTH}</td>
                  <td>{data.WALK_C_AREA}</td>
                  <td>{data.WALK_C_JOBS}</td>
                  <td>{data.WALK_C_AMEN}</td>
                  <td>{data.WALK_C_POP}</td>
                  <td>{data.WALK_C_GFA}</td>
                  <td>{data.WALK_C_VACANT}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Network</td>
                  <td>{data.WALK_N_LENGTH}</td>
                  <td>{data.WALK_N_AREA}</td>
                  <td>{data.WALK_N_JOBS}</td>
                  <td>{data.WALK_N_AMEN}</td>
                  <td>{data.WALK_N_POP}</td>
                  <td>{data.WALK_N_GFA}</td>
                  <td>{data.WALK_N_VACANT}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Perceived</td>
                  <td>{data.WALK_P_LENGTH}</td>
                  <td>{data.WALK_P_AREA}</td>
                  <td>{data.WALK_P_JOBS}</td>
                  <td>{data.WALK_P_AMEN}</td>
                  <td>{data.WALK_P_POP}</td>
                  <td>{data.WALK_P_GFA}</td>
                  <td>{data.WALK_P_VACANT}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Scenario 1</td>
                  <td>{data.WALK_SC1_LENGTH}</td>
                  <td>{data.WALK_SC1_AREA}</td>
                  <td>{data.WALK_SC1_JOBS}</td>
                  <td>{data.WALK_SC1_AMEN}</td>
                  <td>{data.WALK_SC1_POP}</td>
                  <td>{data.WALK_SC1_GFA}</td>
                  <td>{data.WALK_SC1_VACANT}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Scenario 2</td>
                  <td>{data.WALK_SC2_LENGTH}</td>
                  <td>{data.WALK_SC2_AREA}</td>
                  <td>{data.WALK_SC2_JOBS}</td>
                  <td>{data.WALK_SC2_AMEN}</td>
                  <td>{data.WALK_SC2_POP}</td>
                  <td>{data.WALK_SC2_GFA}</td>
                  <td>{data.WALK_SC2_VACANT}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Scenarios 1+2</td>
                  <td>{data.WALK_SC12_LENGTH}</td>
                  <td>{data.WALK_SC12_AREA}</td>
                  <td>{data.WALK_SC12_JOBS}</td>
                  <td>{data.WALK_SC12_AMEN}</td>
                  <td>{data.WALK_SC12_POP}</td>
                  <td>{data.WALK_SC12_GFA}</td>
                  <td>{data.WALK_SC12_VACANT}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                </table>
                <br /><br />
                <MeasureSelect/>
                <ResponsiveContainer 
                  width="99%" aspect={2}>
                  <BarChart
                    data={tempWalkData}
                    layout="vertical"
                    margin={{
                      top: 5, right: 80, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number"/>
                    <YAxis type="category" dataKey="name"/>
                    <Tooltip />
                    <Bar dataKey={currMeasure} />
                  </BarChart>
                </ResponsiveContainer>
                <br /><br />
                <hr></hr>
                Bikesheds
                <table className="stationsDataTable">
                <tr>
                  <th>Scenario</th>
                  <th>Length (mi)</th>
                  <th>Area (sq mi)</th>
                  <th>Employment</th>
                  <th>Amenities (N)</th>
                  <th>Population</th>
                  <th>Building Area (GFA M sq ft)</th>
                  <th>Vacant Area (M sq ft)</th>
                  <th>Bike Paths</th>
                  <th>Bike Lanes</th>
                  <th>Bike Routes</th>
                  <th>Bike Facilities</th>
                </tr>
                <tr>
                  <td>Circle</td>
                  <td>{data.BIKE_C_LENGTH}</td>
                  <td>{data.BIKE_C_AREA}</td>
                  <td>{data.BIKE_C_JOBS}</td>
                  <td>{data.BIKE_C_AMEN}</td>
                  <td>{data.BIKE_C_POP}</td>
                  <td>{data.BIKE_C_GFA}</td>
                  <td>{data.BIKE_C_VACANT}</td>
                  <td>{data.BIKE_C_PATH}</td>
                  <td>{data.BIKE_C_LANE}</td>
                  <td>{data.BIKE_C_ROUTE}</td>
                  <td>{data.BIKE_C_FACILITIES}</td>
                </tr>
                <tr>
                  <td>Network</td>
                  <td>{data.BIKE_N_LENGTH}</td>
                  <td>{data.BIKE_N_AREA}</td>
                  <td>{data.BIKE_N_JOBS}</td>
                  <td>{data.BIKE_N_AMEN}</td>
                  <td>{data.BIKE_N_POP}</td>
                  <td>{data.BIKE_N_GFA}</td>
                  <td>{data.BIKE_N_VACANT}</td>
                  <td>{data.BIKE_N_PATH}</td>
                  <td>{data.BIKE_N_LANE}</td>
                  <td>{data.BIKE_N_ROUTE}</td>
                  <td>{data.BIKE_N_FACILITIES}</td>
                </tr>
                <tr>
                  <td>Perceived</td>
                  <td>{data.BIKE_P_LENGTH}</td>
                  <td>{data.BIKE_P_AREA}</td>
                  <td>{data.BIKE_P_JOBS}</td>
                  <td>{data.BIKE_P_AMEN}</td>
                  <td>{data.BIKE_P_POP}</td>
                  <td>{data.BIKE_P_GFA}</td>
                  <td>{data.BIKE_P_VACANT}</td>
                  <td>{data.BIKE_P_PATH}</td>
                  <td>{data.BIKE_P_LANE}</td>
                  <td>{data.BIKE_P_ROUTE}</td>
                  <td>{data.BIKE_P_FACILITIES}</td>
                </tr>
                <tr>
                  <td>Scenario 3</td>
                  <td>{data.BIKE_SC3_LENGTH}</td>
                  <td>{data.BIKE_SC3_AREA}</td>
                  <td>{data.BIKE_SC3_JOBS}</td>
                  <td>{data.BIKE_SC3_AMEN}</td>
                  <td>{data.BIKE_SC3_POP}</td>
                  <td>{data.BIKE_SC3_GFA}</td>
                  <td>{data.BIKE_SC3_VACANT}</td>
                  <td>{data.BIKE_SC3_PATH}</td>
                  <td>{data.BIKE_SC3_LANE}</td>
                  <td>{data.BIKE_SC3_ROUTE}</td>
                  <td>{data.BIKE_SC3_FACILITIES}</td>
                </tr>
                <tr>
                  <td>Scenario 4</td>
                  <td>{data.BIKE_SC4_LENGTH}</td>
                  <td>{data.BIKE_SC4_AREA}</td>
                  <td>{data.BIKE_SC4_JOBS}</td>
                  <td>{data.BIKE_SC4_AMEN}</td>
                  <td>{data.BIKE_SC4_POP}</td>
                  <td>{data.BIKE_SC4_GFA}</td>
                  <td>{data.BIKE_SC4_VACANT}</td>
                  <td>{data.BIKE_SC4_PATH}</td>
                  <td>{data.BIKE_SC4_LANE}</td>
                  <td>{data.BIKE_SC4_ROUTE}</td>
                  <td>{data.BIKE_SC4_FACILITIES}</td>
                </tr>
                <tr>
                  <td>Scenarios 3+4</td>
                  <td>{data.BIKE_SC34_LENGTH}</td>
                  <td>{data.BIKE_SC34_AREA}</td>
                  <td>{data.BIKE_SC34_JOBS}</td>
                  <td>{data.BIKE_SC34_AMEN}</td>
                  <td>{data.BIKE_SC34_POP}</td>
                  <td>{data.BIKE_SC34_GFA}</td>
                  <td>{data.BIKE_SC34_VACANT}</td>
                  <td>{data.BIKE_SC34_PATH}</td>
                  <td>{data.BIKE_SC34_LANE}</td>
                  <td>{data.BIKE_SC34_ROUTE}</td>
                  <td>{data.BIKE_SC34_FACILITIES}</td>
                </tr>
              </table>
              <br /><br />
              <MeasureSelect/>
              <ResponsiveContainer 
                  width="99%" aspect={2}>
                  <BarChart
                    data={tempBikeData}
                    layout="vertical"
                    margin={{
                      top: 5, right: 80, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number"/>
                    <YAxis type="category" dataKey="name"/>
                    <Tooltip />
                    <Bar dataKey={currMeasure} />
                  </BarChart>
                </ResponsiveContainer>
            </div>
      )
    }
  }
  // console.log(Stations)
  return (
    <MapContainer>
      <DeckGL
        style={{mixBlendMode:((currMap==="mapbox://styles/dhalpern/ckcy62n4k1j6p1jpb2dqb7kdk?fresh=true")?"color-burn":(currMap==="mapbox://styles/dhalpern/ckdi2k80w06lo1ipie0amua63?fresh=true")?"multiply":"hard-light")}}
        ref={deckRef}
        layers={layers(currLayers)}
        effects={effects}
        initialViewState={viewState}
        parameters={WEBGL_PARAMETERS}
        controller={true}
        onWebGLInitialized={setGLContext}
        getTooltip={getTooltip}
      >
        {glContext && (
          <StaticMap
            reuseMaps
            ref={mapRef}
            mapStyle={currMap}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            preventStyleDiffing={true}
            // onLoad={onMapLoad}
          />
        )}
      </DeckGL>
      <LayersSelect className="layer-select">
        <Paper elevation={5} className={legend1Open?"":"hidden"}>
        <Button className="closeLegend" onClick={() => setLegend1Open(false)}>×</Button>
          <Typography>
            Current Access - Pedestrian
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={<WalkEucCheck checked={currLayers.walkEuclidean} onChange={handleLayers} name="walkEuclidean" />}
              label="Walk Circle"
            />
            <FormControlLabel
            control={<WalkNetworkCheck checked={currLayers.walkNetwork} onChange={handleLayers} name="walkNetwork" />}
            label="Walk Network"
            />
            <FormControlLabel
              control={<WalkPerceivedCheck checked={currLayers.walkPerceived} onChange={handleLayers} name="walkPerceived" />}
              label="Walk Perceived"
            />
          </FormGroup>
          <hr></hr>
          <Typography>
            Scenarios
          </Typography>

          <FormGroup row>
            
            <FormControl component="fieldset">
              <RadioGroup value={currWalkRadio} onChange={handleWalkRadio} onClick={handleWalkReset} row>

                <FormControlLabel control={<ScenarioRadio />} value="walkSc3" label="Scenario 1" />
                <Button className={currWalkScenario===WalkScenarios[0]?"infoButton active":"infoButton"} onClick={() => handleWalkScenario(0)}>i</Button>
                
                <FormControlLabel control={<ScenarioRadio />} value="walkSc4" label="Scenario 2" />
                <Button className={currWalkScenario===WalkScenarios[1]?"infoButton active":"infoButton"} onClick={() => handleWalkScenario(1)}>i</Button>
                
                <FormControlLabel control={<ScenarioRadio />} value="walkSc34" label="Scenario 1 + 2" />
                <Button className={currWalkScenario===WalkScenarios[2]?"infoButton active":"infoButton"} onClick={() => handleWalkScenario(2)}>i</Button>
              
              </RadioGroup>
            </FormControl>
          </FormGroup>
          <p className="scenarioText">{currWalkScenario}</p>
        </Paper>
        <Paper elevation={5} className={legend2Open?"":"hidden"}>
        <Button className="closeLegend" onClick={() => setLegend2Open(false)}>×</Button>
        <Typography>
          Current Access - Bike
        </Typography>
          <FormGroup row>
            <FormControlLabel
              control={<BikeEucCheck checked={currLayers.bikeEuclidean} onChange={handleLayers} name="bikeEuclidean" />}
              label="Bike Circle"
            />
            <FormControlLabel
            control={<BikeNetworkCheck checked={currLayers.bikeNetwork} onChange={handleLayers} name="bikeNetwork" />}
            label="Bike Network"
            />
            <FormControlLabel
              control={<BikePerceivedCheck checked={currLayers.bikePerceived} onChange={handleLayers} name="bikePerceived" />}
              label="Bike Perceived"
            />
          </FormGroup>
          <hr></hr>
          <Typography>
            Scenarios
          </Typography>
          <FormGroup row>
            <FormControl component="fieldset">
              <RadioGroup value={currBikeRadio} onChange={handleBikeRadio} onClick={handleBikeReset} row>

                <FormControlLabel control={<ScenarioRadio />} value="bikeSc1" label="Scenario 3" />
                <Button className={currBikeScenario===BikeScenarios[0]?"infoButton active":"infoButton"} onClick={() => handleBikeScenario(0)}>i</Button>
                
                <FormControlLabel control={<ScenarioRadio />} value="bikeSc2" label="Scenario 4" />
                <Button className={currBikeScenario===BikeScenarios[1]?"infoButton active":"infoButton"} onClick={() => handleBikeScenario(1)}>i</Button>
                
                <FormControlLabel control={<ScenarioRadio />} value="bikeSc12" label="Scenario 3 + 4" />
                <Button className={currBikeScenario===BikeScenarios[2]?"infoButton active":"infoButton"} onClick={() => handleBikeScenario(2)}>i</Button>
              
              </RadioGroup>
            </FormControl>
          </FormGroup>
          <p className="scenarioText">{currBikeScenario}</p>
        </Paper>
      </LayersSelect>

      <Button id="openLegend" style={{display:((legend1Open&&legend2Open)?"none":""), zIndex:50, transform:((!legend1Open&&!legend2Open)?"":"translateY(200px)")}} onClick={() => handleLegendOpen()}><Paper>Legend</Paper></Button>
      
      <BaseMapSelect elevation={5}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Base Map</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currMap}
            onChange={handleBaseMap}
          >
            <MenuItem value={`mapbox://styles/dhalpern/ckcy62n4k1j6p1jpb2dqb7kdk?fresh=true`}>Streets</MenuItem>
            <MenuItem value={`mapbox://styles/dhalpern/ck9at4p4w1h081illlzoircd0?fresh=true`}>Satellite</MenuItem>
            <MenuItem value={`mapbox://styles/dhalpern/ckdi2k80w06lo1ipie0amua63?fresh=true`}>Simple</MenuItem>
          </Select>
        </FormControl>
      </BaseMapSelect>
      <DrawerButton onClick={toggleDrawer(true)}><Paper>summary</Paper></DrawerButton>
      <SideBarDrawer open={drawer} onClose={toggleDrawer(false)} anchor={"left"} className="drawer-transparent" id="main-drawer">
        <Button className="closeButton" onClick={toggleDrawer(false)}>×</Button>
        <Typography variant="h1">
          Transit Access
        </Typography>
        <Typography variant="h2">
          Improving Walking and Biking to Commuter Rail Stations in Greater Boston.
        </Typography>
        <Typography>
          <br></br>
          This map describes pedestrian and bicycle access to commuter-rail stations in the greater Boston area by comparing three definitions of catchment areas—1) as a crow-flies distance (circle distance), 2) network distance and 3) “perceived” distance to the nearest station. Pedestrian- and bicycle path-choice studies have shown that people's perception of distance can substantially differ from objectively measured distance. For instance, walking along routes with high traffic volumes and speeds tends to make the walk feel longer than it is, while passing by ground-businesses or greenery makes distance feel shorter. Similarly, having quality bike paths makes biking distances feel shorter, while having to bike against traffic has the opposite effect. Using behavioral findings from prior studies (1,2), the maps below illustrate how much smaller the perceived ~10 minute walk- and bike-sheds are compared to the “network” and “as-a-crow-flies” catchment areas around current commuter rail stations around in the system. All walk-sheds are mapped using a ½ mile distance, bike-sheds using a 1½ mile distance. It is within the “perceived” catchment areas—particularly the “perceived” ½ mile walking zones—that people “feel” they can get to stations within approx. 10 minutes with non-motorized transportation, delineating critical areas for Transit Oriented Development and transit-improvement impacts.<br></br><br></br>

          Summary statistics below indicate what percentage of residents, jobs, gross floor area (GFA) and vacant land are contained within the “perceived” and network catchment areas, compared to the as-a-crow-flies walk (½ mile) and bike (1½ miles) zones around stations.<br></br><br></br>

          Four potential policy options are also presented. To improve walking conditions, the map explores how a) a 20-mph maximum speed limit on all roads that currently have speed limits below 55-mph and b) a 50% increase in ground-floor amenities could impact the “perceived” ½ mile walksheds around stations. To improve bike access to stations, the map explores how c) allowing two-way biking on all one-way streets within ½ miles of stations and d) implementing protected bike lanes on all streets within ½ miles of stations, with bike routes (bikes mixed with traffic, with painted signs on roadways) within the rest of the 1½ mile bike sheds around stations. The summary charts describe how each of these scenarios could reduce the “perceived” distance to the nearest commuter rail station, improving access to residents, jobs, GFA and unbuilt parcels in the commuter rail system as a whole. The interactive map can be used to explore potential impacts at particular stations.<br></br><br></br>

          <b>Downloads</b><br></br>
          <a href={`${process.env.PUBLIC_URL}/downloads/Transit_Access_Final_Report.pdf`} rel="noreferrer noopener">Report</a><br></br>
          <a href={`${process.env.PUBLIC_URL}/downloads/TransitAccessData.xlsx`} target="_blank" rel="noreferrer noopener">Findings Table</a><br></br>
          <a href={`${process.env.PUBLIC_URL}/downloads/Polygons_w_Scenarios.zip`} target="_blank" rel="noreferrer noopener">Geospatial Data</a><br></br><br></br>

          <b>References:</b><br></br>
          1.    Sevtsuk, A., Li, X., Basu, R., &amp; Kalvo, R. (upcoming). A big data approach to understanding pedestrian route choice preferences - Evidence from San Francisco. Travel Behavior and Society.<br></br>
          2.    Hood, J., Sall, E., &amp; Charlton, B. (2011). A GPS-based bicycle route choice model for San Francisco, California. Transportation Letters, 3(1), 63–75<br></br><br></br>

          <b>Credits</b><br></br>
          <a href="https://dusp.mit.edu/faculty/andres-sevtsuk" className="peopleLink" target="_blank" rel="noopener noreferrer">Andres Sevtsuk</a>, Director City Form Lab, MIT<br></br>
          <a href="https://www.linkedin.com/in/ruben-morgan-rosello/" className="peopleLink" target="_blank" rel="noopener noreferrer">Rubén Morgan</a>, MCP/MST 2021, MIT<br></br>
          <a href="https://www.linkedin.com/in/sarah-fayad-35561869/" className="peopleLink" target="_blank" rel="noopener noreferrer">Sarah Fayad</a>, MLAUD 2020, Harvard GSD<br></br>
          <a href="https://www.linkedin.com/in/dylanhalpern/" className="peopleLink" target="_blank" rel="noopener noreferrer">Dylan Halpern</a>, MCP 2020, MIT<br></br><br></br>

        </Typography>
        <AppBar position="static">
          <Tabs value={tabValue} onChange={handleTab} variant="fullWidth" className="drawerTabs">
            <Tab label="Current Access" {...a11yProps(0)} />
            <Tab label="Scenarios" {...a11yProps(1)} />
            <Tab label="Station Information" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={tabValue} index={0} style={{maxWidth:"100%",overFlowX:"scroll"}}>
          <Typography variant="h1">
            <hr></hr>
            Summary Statistics: Pedestrian Access
          </Typography>
          <MeasureSelect />
          <Grid container spacing={3} className="chartGrid">
            <Grid item xs={12}>
              <Typography style={{textAlign:"center"}}>
              <br></br><br></br>{currMeasure} ({DataUnits[currMeasure]})
              </Typography>
              <ResponsiveContainer width="99%" aspect={1} className="radial">
                <RadialBarChart 
                  innerRadius="50%" 
                  outerRadius="100%" 
                  data={WalkData} 
                  startAngle={180} 
                  endAngle={0}
                >
                  <RadialBar minAngle={15} label={{ fill: '#fff', position: 'insideStart', fontWeight:"bold", units:" GFA" }} background clockWise={true} dataKey={currMeasure}>
                    <LabelList dataKey={`${currMeasure}_PCT`} position="end" fill="#000000" />
                  </RadialBar>
                  <Legend iconSize={10} layout='horizontal' verticalAlign='middle' align="center" style={{transform:"translateY(20px)"}} className="legendRadial"/>
                  {/* <Tooltip /> */}
                </RadialBarChart>
              </ResponsiveContainer>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <table className="MetricsTable">
                    <tr>
                      <th>Metric</th>
                      {
                        DataMeasures.map(o=>{
                          return(
                            <th>{o}</th>
                          )
                        })
                      }
                    </tr>
                    {
                      WalkData.map(o=>{
                        return (
                          <tr>
                            <td>
                              {o['name']}
                            </td>
                            {
                              DataMeasures.map(i=>{
                                return(
                                  <td>{o[i].toLocaleString()}</td>
                                )
                              })
                            }
                          </tr>
                        )
                      })
                    }
                  </table>
                  {/* <Typography>
                  <b>Perceived</b><br></br>
                  Built Access (M ft²): <b>2,482</b><br></br>
                  % of Straight Line: <b>30.2%</b><br></br><br></br>
                  Vacant Access (M ft²): <b>8.2</b><br></br>
                  % of Straight Line: <b>15.3%</b><br></br><br></br>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>
                  <b>Network</b><br></br>
                  Built Access (M ft²): <b>5,271</b><br></br>
                  % of Straight Line: <b>64.1%</b><br></br><br></br>
                  Vacant Access (M ft²): <b>17.0</b><br></br>
                  % of Straight Line: <b>31.8%</b><br></br><br></br>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>
                  <b>Euclidean</b><br></br>
                  Built Access (M ft²): <b>8,226</b><br></br>
                  % of Straight Line: <b>100%</b><br></br><br></br>
                  Vacant Access (M ft²): <b>53.4</b><br></br>
                  % of Straight Line: <b>100%</b><br></br><br></br>
                  </Typography> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography variant="h1">
            <hr></hr>
            Summary Statistics: Bicycle Access
          </Typography>
          <MeasureSelect />
          <Grid container spacing={3} className="chartGrid">
            <Grid item xs={12}>
              <Typography style={{textAlign:"center"}}>
              <br></br><br></br>{currMeasure} ({DataUnits[currMeasure]})
              </Typography>
              <ResponsiveContainer width="99%" aspect={1} className="radial">
                <RadialBarChart 
                  innerRadius="50%" 
                  outerRadius="100%" 
                  data={BikeData} 
                  startAngle={180} 
                  endAngle={0}
                >
                  <RadialBar minAngle={15} label={{ fill: '#fff', position: 'insideStart', fontWeight:"bold", units:" GFA" }} background clockWise={true} dataKey={currMeasure}>
                    <LabelList dataKey={`${currMeasure}_PCT`} position="end" fill="#000000" />
                  </RadialBar>
                  <Legend iconSize={10} layout='horizontal' verticalAlign='middle' align="center" style={{transform:"translateY(20px)"}} className="legendRadial"/>
                  {/* <Tooltip /> */}
                </RadialBarChart>
              </ResponsiveContainer>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <table className="MetricsTable">
                        <tr>
                          <th>Metric</th>
                          {
                            DataMeasures.map(o=>{
                              return(
                                <th>{o}</th>
                              )
                            })
                          }
                        </tr>
                        {
                          BikeData.map(o=>{
                            return (
                              <tr>
                                <td>
                                  {o['name']}
                                </td>
                                {
                                  DataMeasures.map(i=>{
                                    return(
                                      <td>{o[i].toLocaleString()}</td>
                                    )
                                  })
                                }
                              </tr>
                            )
                          })
                        }
                    </table>
              {/* <Typography>
              <b>Perceived</b><br></br>
              Built Access (M ft²): <b>8,000</b><br></br>
              % of Straight Line: <b>26.7%</b><br></br><br></br>
              Vacant Access (M ft²): <b>30.9</b><br></br>
              % of Straight Line: <b>6.6%</b><br></br><br></br>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>
              <b>Network</b><br></br>
              Built Access (M ft²): <b>22,208</b><br></br>
              % of Straight Line: <b>74.2%</b><br></br><br></br>
              Vacant Access (M ft²): <b>99.9</b><br></br>
              % of Straight Line: <b>21.4%</b><br></br><br></br>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>
              <b>Euclidean</b><br></br>
              Built Access (M ft²): <b>29,930</b><br></br>
              % of Straight Line: <b>100%</b><br></br><br></br>
              Vacant Access (M ft²): <b>465.8</b><br></br>
              % of Straight Line: <b>100%</b><br></br><br></br>
              </Typography> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Typography variant="h1">
            <br></br><br></br>
            <hr></hr>
            Scenarios: Pedestrian Access
          </Typography>
          <MeasureSelect />
          <br></br><br></br>{currMeasure} ({DataUnits[currMeasure]})
          <Grid container spacing={3} className="BarGrid">
            <Grid item xs={12}>
              <ResponsiveContainer 
                  width="99%" aspect={2}>
                <BarChart
                  data={WalkScData}
                  layout="vertical"
                  margin={{
                    top: 5, right: 80, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number"/>
                  <YAxis type="category" dataKey="name"/>
                  <Tooltip />
                  <Legend />
                  <Bar dataKey={currMeasure}>
                    <LabelList dataKey={`${currMeasure}_PCT`} position="right" fill="#000000" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div class="scenariosDescription">
                <p>
                  {WalkScenarios[0]}
                </p>
                <p>
                  {WalkScenarios[1]}
                </p>
                <p>
                  {WalkScenarios[2]}
                </p>
              </div>
            </Grid>
          </Grid>
          <Typography variant="h1">
            <br></br><br></br>
            <hr></hr>
            Scenarios: Bicycle Access
          </Typography>
          <MeasureSelect />
          <br></br><br></br>{currMeasure} ({DataUnits[currMeasure]})
          <Grid container spacing={3} className="BarGrid">
            <Grid item xs={12}>
              <ResponsiveContainer 
                  width="99%" aspect={2}>
                <BarChart
                  data={BikeScData}
                  layout="vertical"
                  margin={{
                    top: 5, right: 80, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number"/>
                  <YAxis type="category" dataKey="name"/>
                  <Tooltip />
                  <Legend />
                  <Bar dataKey={currMeasure}>
                    <LabelList dataKey={`${currMeasure}_PCT`} position="right" fill="#000000" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div class="scenariosDescription">
                <p>
                  {BikeScenarios[0]}
                </p>
                <p>
                  {BikeScenarios[1]}
                </p>
                <p>
                  {BikeScenarios[2]}
                </p>
              </div>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Autocomplete
            id="combo-box-demo"
            options={Stations}
            getOptionLabel={(option) => option.Name}
            style={{ width: 300 }}
            onChange={handleAutoComplete}
            renderInput={(params) => <TextField {...params} label="Station search" variant="outlined" />}
          />
            {StationDataTable(currStationData)}
        </TabPanel>
      </SideBarDrawer>
      <LogoContainer>
        <Paper>
          <a href="http://cityform.mit.edu/" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/city_form.png`} alt="City Form Lab Logo"></img>
          </a>
          <a href="https://www.sasaki.com/" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/sasaki.png`} alt="Sasaki Associates Logo"></img>
          </a>
          <a href="https://www.barrfoundation.org/" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/barrfoundation.png`} alt="Barr Foundation Logo"></img>
          </a>
        </Paper>
      </LogoContainer>
    </MapContainer>
  );
}